@import url("https://fonts.googleapis.com/css2?family=Google+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap");
body {
  font-family: "Quicksand", sans-serif;
}
#app {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Quicksand", sans-serif;
}
